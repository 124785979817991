import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Textarea,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import cookie from 'cookie';

const getCookie = (name) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[name];
};

export default function CreateAutomationModal({ isOpen, onClose, createFlow }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loyaltyPrograms, setLoyaltyPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState('');

  const CompanyToken = getCookie('x-company');
  const UserToken = getCookie('token');

  useEffect(() => {
    if (isOpen) {
      axios
        .get('https://api.perxify.com/loyalty-programs', {
          headers: {
            'x-company': CompanyToken,
            Authorization: `Bearer ${UserToken}`,
          },
        })
        .then((response) => {
          setLoyaltyPrograms(response.data);
        })
        .catch((error) => {
          console.error('Error fetching loyalty programs:', error);
        });
    }
  }, [CompanyToken, UserToken, isOpen]);

  const handleSubmit = async () => {
    createFlow(name, description, selectedProgram);
    console.log('selectedProgram', selectedProgram);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Flow</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            placeholder="Enter flow name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            mb={3}
            required={true}
          />
          <Textarea
            placeholder="Enter flow description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            mb={3}
            required={true}
          />
          <Select
            placeholder="Select loyalty program"
            value={selectedProgram}
            onChange={(e) => setSelectedProgram(e.target.value)}
            required={true}
          >
            {loyaltyPrograms.map((program) => (
              <option key={program.uuid} value={program.uuid}>
                {program.name}
              </option>
            ))}
          </Select>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isDisabled={!name || !description || !selectedProgram}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
