import axios from 'axios';
import cookie from 'cookie';

const getCookie = (name) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[name];
};

const CompanyToken = getCookie('x-company');
const UserToken = getCookie('token');

export const fetchAutomations = async () => {
  const response = await axios.get(
    `https://api.perxify.com/loyalty-automations/`,
    {
      headers: {
        'x-company': CompanyToken,
        Authorization: `Bearer ${UserToken}`,
      },
    },
  );
  return response.data;
};

export const fetchAutomationRules = async (uuid) => {
  const response = await axios.get(
    `https://api.perxify.com/loyalty-automation-rules?automation_uuid=${uuid}`,
    {
      headers: {
        'x-company': CompanyToken,
        Authorization: `Bearer ${UserToken}`,
      },
    },
  );
  return response.data;
};

export const createLoyaltyAutomation = async (data) => {
  console.log('data', data);
  try {
    const response = await axios.post(
      'https://api.perxify.com/loyalty-automations',
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty automation:', error);
  }
};

export const createLoyaltyAutomationRule = async (data) => {
  try {
    const response = await axios.post(
      'https://api.perxify.com/loyalty-automation-rules',
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty automation rule:', error);
  }
};

export const deleteLoyaltyAutomation = async (uuid) => {
  try {
    await axios.delete(`https://api.perxify.com/loyalty-automations/${uuid}`, {
      headers: {
        'x-company': CompanyToken,
        Authorization: `Bearer ${UserToken}`,
      },
    });
  } catch (error) {
    console.error('Error deleting loyalty automation:', error);
  }
};

export const deleteLoyaltyAutomationRule = async (uuid) => {
  try {
    await axios.delete(
      `https://api.perxify.com/loyalty-automation-rules/${uuid}`,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
  } catch (error) {
    console.error('Error deleting loyalty automation:', error);
  }
};

export const updateLoyaltyAutomation = async (data) => {
  try {
    await axios.put(
      `https://api.perxify.com/loyalty-automations/${data.uuid}`,
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
  } catch (error) {
    console.error('Error updating loyalty automation:', error);
  }
};

export const updateLoyaltyAutomationRule = async (data) => {
  try {
    await axios.put(
      `https://api.perxify.com/loyalty-automation-rules/${data.uuid}`,
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
  } catch (error) {
    console.error('Error updating loyalty automation rule:', error);
  }
};

export const fetchLoyaltyPrograms = async () => {
  try {
    const response = await axios.get(
      'https://api.perxify.com/loyalty-programs',
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching loyalty programs:', error);
  }
};

export const fetchVouchers = async () => {
  try {
    const response = await axios.get(
      'https://api.perxify.com/products?product_type=all',
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching vouchers:', error);
  }
};
