import axios from 'axios';
import cookie from 'cookie';

const getCookie = (name) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[name];
};

const CompanyToken = getCookie('x-company');
const UserToken = getCookie('token');

const axiosHeaders = {
  headers: {
    Authorization: `Bearer ${UserToken}`,
    'x-company': CompanyToken,
  },
};

export const handleImageUpload = async (files, uuid) => {
  const file = files[0]; // Only process the first file for now
  const formData = new FormData();
  formData.append('file', file);

  if (!uuid) {
    throw new Error(
      'No UUID provided. Please save the product before uploading images.',
    );
  }

  try {
    const response = await axios.post(
      `https://api.perxify.com/products/${uuid}/images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...axiosHeaders.headers,
        },
      },
    );
    console.log('response', response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleSetFeaturedImage = async (productUuid, imageUuid) => {
  try {
    const response = await axios.put(
      `https://api.perxify.com/products/${productUuid}/images/${imageUuid}/featured`,
      {},
      axiosHeaders,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const handleDeleteImage = async (productUuid, imageUuid) => {
  try {
    const response = await axios.delete(
      `https://api.perxify.com/products/${productUuid}/images/${imageUuid}`,
      axiosHeaders,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
