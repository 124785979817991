import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = 'https://api.perxify.com';

export const fetchCompanies = async (token) => {
  const response = await axios.get(`${API_URL}/companies`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const setCompanyCookie = (company) => {
  if (company) {
    const companyToken = company.x_company_token;
    // Cookies are secured with the secure and sameSite attributes for better security.
    Cookies.set('x-company', companyToken, {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
      expires: 7 // 1 week in days
    });
  } else {
    throw new Error('No companies found for this user');
  }
};