import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {
    Grid, Text, Box, Button,
} from '@chakra-ui/react';

// Import custom components
import Banner from 'components/company/Banner';
import General from 'components/users/General';
import Notifications from 'components/users/Notifications';
// Assets
import banner from 'assets/img/auth/banner.png';
import avatar from 'assets/img/avatars/perxify_logo.png';

// Import API functions
import {getCompanyDetails, deleteCompany} from '../../api/company';

export default function CompaniesDetail() {
    const {companyUuid} = useParams();
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const data = await getCompanyDetails(companyUuid);
                setCompanyData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching company data', error);
            }
        };

        fetchCompanyData();
    }, [companyUuid]);

    const handleDelete = async () => {
        try {
            await deleteCompany(companyUuid);
            setSuccessMessage('Company deleted successfully');
            setTimeout(() => {
                navigate('/admin/superadmin/companies');
            }, 200);
        } catch (error) {
            console.error('Error deleting company', error);
        }
    };


    if (loading) {
        return <Text>Loading...</Text>;
    }

    if (!companyData) {
        return <Text>No company data found</Text>;
    }

    const {name, program_title, description, created_at, purchases, money_spent, claims} = companyData;

    return (<Box pt={{base: '130px', md: '80px', xl: '80px'}}>
        <Grid
            templateColumns={{
                base: '1fr', lg: '1.34fr 1fr 1.62fr',
            }}
            templateRows={{
                base: 'repeat(3, 1fr)', lg: '1fr',
            }}
            gap={{base: '20px', xl: '20px'}}
        >
            <Banner
                gridArea="1 / 1 / 2 / 2"
                banner={banner}
                avatar={avatar}
                name={name}
                purchases={purchases}
                moneySpent={money_spent}
                claims={claims}
            />
            <General
                gridArea={{base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3'}}
                minH="365px"
                pe="20px"
                companyData={companyData}
            />
            <Notifications
                gridArea={{base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3'}}
                minH="365px"
                pe="20px"
                used={companyData.notifications_used}
                total={companyData.notifications_total}
            />
        </Grid>
        <Grid
            mb="20px"
            templateColumns={{
                base: '1fr', lg: 'repeat(2, 1fr)', '2xl': '1.34fr 1.62fr 1fr',
            }}
            templateRows={{
                base: '1fr', lg: 'repeat(2, 1fr)', '2xl': '1fr',
            }}
            gap={{base: '20px', xl: '20px'}}
        >
            <Box
                gridArea="1 / 1 / 2 / 2"
                p="4"
                borderWidth="1px"
                borderRadius="md"
                mb="4"
            >
                <Text fontSize="xl" fontWeight="bold">
                    Company Details
                </Text>
                <Box mt="4">
                    <Text fontWeight="bold">Name:</Text>
                    <Text>{name}</Text>
                </Box>
                <Box mt="4">
                    <Text fontWeight="bold">Program Title:</Text>
                    <Text>{program_title}</Text>
                </Box>
                <Box mt="4">
                    <Text fontWeight="bold">Description:</Text>
                    <Text>{description}</Text>
                </Box>
                <Box mt="4">
                    <Text fontWeight="bold">Created At:</Text>
                    <Text>{new Date(created_at).toLocaleDateString()}</Text>
                </Box>
                <Button
                    mt="4"
                    colorScheme="red"
                    onClick={handleDelete}
                >
                    Delete Company
                </Button>
                {successMessage && (<Text mt="4" color="green.500">
                    {successMessage}
                </Text>)}
            </Box>
        </Grid>
    </Box>);
}