/*!
=========================================================
* Create new product page
=========================================================
*/
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  SimpleGrid,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import TagsField from 'components/fields/TagsField';
import Dropzone from 'views/admin/main/ecommerce/newProduct/components/Dropzone';
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';

import cookie from 'cookie';

const getCookie = (name) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[name];
};

const CompanyToken = getCookie('x-company');
const UserToken = getCookie('token');


// Post new product to API
function postNewProduct (name, price, productImage, description="", loyalty_program_id=null, loyalty_level_id=null,
                         feature=false)
{
  const url = 'https://api.perxify.com/products';
  const data = {
    name: name,
    description: description,
    price: price,
    loyalty_program_id: loyalty_program_id, //optional
    loyalty_level_id: loyalty_level_id, //optional
    is_featured: feature //optional
  };

  let levelModalData = {
    product_uuid: '',
    loyalty_uuid: '',
    name: '',
    description: '',
    price: 0,
    expirationTimePeriod: 0,
    expirationTimeUnit: 'days',
    is_archived: false,
    is_active: true,
    quantity: 0,
    is_infinite: false,
    has_expiration: false,
  };

  console.log("Hello world!");
  console.log(data)

  //Post basic data
  axios.post(url,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserToken}`,
          'x-company': CompanyToken,
        },
      },
  )
      .then(response => {
        console.log('User created:', response.data);
        //levelModalData = response.data;
        const {uuid, ...levelModalData} = response.data;

        axios.post(
            `${url}/${uuid}/images`,
            productImage,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${UserToken}`,
                'x-company': CompanyToken,
              },
            },
        )
            .then((response) => {
              console.log('Image added:', response.data);
              //setImages([...images, response.data])
            })
            .catch((error) => console.error('Error uploading image:', error));

      })
      .catch(error => {
        console.error('Error creating user:', error);
      });
}

export default function NewProduct() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  });

  const productTab = React.useRef();
  const mediaTab = React.useRef();

  const theme = useTheme();
  //eslint-disable-next-line
  const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
  //eslint-disable-next-line
  const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
  const brand = useColorModeValue(lineColor, lineColorDark);

  const [productName, setProductName] = useState('');
  const handleProductNameChange = (event) => {
    setProductName(event.target.value);
  };

  const [productPrice, setProductPrice] = useState('');
  const handleProductPriceChange = (event) => {
    setProductPrice(event.target.value);
  };

  const [productDescription, setProductDescription] = useState('');
  const handleProductDescriptionChange = (event) => {
    setProductDescription(event.target.value);
  }

  const [formData, setFormData] = useState(new FormData());
  const handleImageUpload = (acceptedFiles) => {
    const newFormData = new FormData();
    newFormData.append('file', acceptedFiles.target.files[0]);
    setFormData(newFormData);
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        mt={{ base: '60px', md: '165px' }}
        zIndex="0"
        display="flex"
        flexDirection="column"
      >
        <TabList
          display="flex"
          alignItems="center"
          alignSelf="center"
          justifySelf="center"
        >
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={productTab}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                product: true,
                media: false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: '120px', md: '250px', lg: '300px' },
                height: '3px',
                bg: activeBullets.media ? 'white' : 'brand.400',
                left: { sm: '12px', md: '40px' },
                top: {
                  sm: activeBullets.product ? '6px' : '4px',
                  md: null,
                },
                position: 'absolute',
                bottom: activeBullets.product ? '40px' : '38px',

                transition: 'all .3s ease',
              }}
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.product ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.product ? 'white' : 'gray.300'}
                fontWeight={activeBullets.product ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Product Info
              </Text>
            </Flex>
          </Tab>
          <Tab
            _focus={{ border: '0px', boxShadow: 'unset' }}
            ref={mediaTab}
            w={{ sm: '120px', md: '250px', lg: '300px' }}
            onClick={() =>
              setActiveBullets({
                product: true,
                media: true,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
            >
              <Box
                zIndex="1"
                border="2px solid"
                borderColor={activeBullets.media ? 'white' : 'brand.400'}
                bgGradient="linear(to-b, brand.400, brand.600)"
                w="16px"
                h="16px"
                mb="8px"
                borderRadius="50%"
              />
              <Text
                color={activeBullets.media ? 'white' : 'gray.300'}
                fontWeight={activeBullets.media ? 'bold' : 'normal'}
                display={{ sm: 'none', md: 'block' }}
              >
                Media
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Product Info
              </Text>
              <Flex direction="column" w="100%">
                <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
                  <Stack direction="column" gap="20px">
                    <InputField
                      mb="0px"
                      id="name"
                      placeholder="eg. Elegant Chair"
                      label="Product Name"
                      value={productName}
                      onChange={handleProductNameChange}
                      isRequired={true}
                    />
                    <InputField
                        id="price"
                        name="price"
                        placeholder="eg. $99"
                        label="Price"
                        value={productPrice}
                        onChange={handleProductPriceChange}
                        isRequired={true}
                    />
                  </Stack>
                  <Stack direction="column" gap="20px">
                    <TextField
                      h="146px"
                      mb="0px"
                      id="Description"
                      placeholder="Short description about the product"
                      label="Description"
                      value={productDescription}
                      onChange={handleProductDescriptionChange}
                      isRequired={true}
                    />
                  </Stack>
                </SimpleGrid>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => mediaTab.current.click()}
                  >
                    Next
                  </Button>
                </Flex>
              </Flex>
            </Card>
          </TabPanel>
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                Media
              </Text>
              <Dropzone
                content={
                  <Box>
                    <Icon
                      as={MdOutlineCloudUpload}
                      w="80px"
                      h="80px"
                      color={textColor}
                    />
                    <Text
                      mx="auto"
                      mb="12px"
                      fontSize="lg"
                      fontWeight="700"
                      whiteSpace="pre-wrap"
                      color={textColor}
                    >
                      Drop your files here, or{' '}
                      <Text
                        as="span"
                        fontSize="lg"
                        fontWeight="700"
                        color={brand}
                      >
                        browse
                      </Text>
                    </Text>
                    <Text
                      fontSize="sm"
                      fontWeight="500"
                      color="secondaryGray.500"
                    >
                      PNG, JPG and GIF files are allowed
                    </Text>
                  </Box>
                }
                onDrop={handleImageUpload}
              />
              <Flex justify="space-between" mt="24px">
                <Button
                  variant="light"
                  fontSize="sm"
                  borderRadius="16px"
                  w={{ base: '128px', md: '148px' }}
                  h="46px"
                  onClick={() => productTab.current.click()}
                >
                  Prev
                </Button>
                <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => postNewProduct(productName, productPrice, formData, productDescription)}
                >
                  Submit
                </Button>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
