import { SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React from 'react';
import Information from 'views/admin/main/profile/overview/components/Information';

export default function GeneralInformation(props) {
  const { ...rest } = props;

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';

  return (
    <Card mb={{ base: '0px', '2xl': '20px' }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        General Information
      </Text>
      <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </Text>
      <SimpleGrid columns="2" gap="20px">
        <Information title="Phone Number" value="+372 5621 8861" />
        <Information title="Organization" value="Simmmple Web LLC" />
        <Information title="Birthday" value="20 July 1986" />
      </SimpleGrid>
    </Card>
  );
}
