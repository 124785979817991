import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = 'https://api.perxify.com';

export const login = async (email, password) => {
  const response = await axios.post(`${API_URL}/auth/login`, {
    email,
    password,
  });

  const { token } = response.data;

  if (token) {
    Cookies.set('token', token, {
      secure: process.env.NODE_ENV !== 'development',
      sameSite: 'Strict',
      expires: 7, // 1 week
    });
  }

  return token;
};
