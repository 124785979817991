import axios from 'axios';
import cookie from 'cookie';

const getCookie = (name) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[name];
};

const CompanyToken = getCookie('x-company');
const UserToken = getCookie('token');

export const getLoyaltyPrograms = async () => {
  const response = await axios.get(
    `https://api.perxify.com/loyalty-programs/`,
    {
      headers: {
        'x-company': CompanyToken,
        Authorization: `Bearer ${UserToken}`,
      },
    },
  );
  return response.data;
};

export const getLoyaltyLevels = async () => {
  const response = await axios.get(`https://api.perxify.com/loyalty-levels/`, {
    headers: {
      'x-company': CompanyToken,
      Authorization: `Bearer ${UserToken}`,
    },
  });
  return response.data;
};

export const updateLoyaltyProgram = async (uuid, data) => {
  try {
    const response = await axios.put(
      `https://api.perxify.com/loyalty-programs/${uuid}`,
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty program:', error);
  }
};

export const updateLoyaltyLevel = async (uuid, data) => {
  try {
    const response = await axios.put(
      `https://api.perxify.com/loyalty-levels/${uuid}`,
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error updating loyalty level:', error);
  }
};

export const updateProduct = async (uuid, data) => {
  try {
    const response = await axios.put(
      `https://api.perxify.com/products/${uuid}`,
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
  }
};

export const createLoyaltyProgram = async (data) => {
  try {
    const response = await axios.post(
      'https://api.perxify.com/loyalty-programs',
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty program:', error);
  }
};

export const createLoyaltyLevel = async (data) => {
  try {
    const response = await axios.post(
      'https://api.perxify.com/loyalty-levels',
      data,
      {
        headers: {
          'x-company': CompanyToken,
          Authorization: `Bearer ${UserToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error creating loyalty level:', error);
  }
};

export const createProduct = async (data) => {
  try {
    const respone = await axios.post('https://api.perxify.com/products', data, {
      headers: {
        'x-company': CompanyToken,
        Authorization: `Bearer ${UserToken}`,
      },
    });
    return respone.data;
  } catch (error) {
    console.error('Error creating product:', error);
  }
};
