import React, {createContext, useContext, useState, useEffect} from 'react';

const NavigationContext = createContext();

export const NavigationProvider = ({children}) => {
    const [breadcrumb, setBreadcrumb] = useState(() => {
        const savedBreadcrumb = sessionStorage.getItem('breadcrumb');
        return savedBreadcrumb ? JSON.parse(savedBreadcrumb) : [];
    });

    useEffect(() => {
        sessionStorage.setItem('breadcrumb', JSON.stringify(breadcrumb));
    }, [breadcrumb]);


    const updateBreadcrumb = (newRoute) => {
        setBreadcrumb((prev) => {
            if (prev.length === 0 || prev[prev.length - 1].path !== newRoute.path) {
                return [...prev, newRoute];
            }
            return prev;
        });
    };

    const resetBreadcrumb = () => {
        setBreadcrumb([]);
    };

    return (<NavigationContext.Provider value={{breadcrumb, updateBreadcrumb, resetBreadcrumb}}>
        {children}
    </NavigationContext.Provider>);
};

export const useNavigation = () => useContext(NavigationContext);
