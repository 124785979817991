// components/navbar/AdminNavbar.js

import React, {useEffect, useState} from 'react';
import {Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue,} from '@chakra-ui/react';
import {useLocation, useNavigate} from 'react-router-dom';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import {useNavigation} from '../../contexts/NavigationContext';
import routes from '../../routes.js';
import {findCurrentRoute} from '../../utils/routeUtils';

export default function AdminNavbar(props) {
    const [, setScrolled] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {breadcrumb, updateBreadcrumb} = useNavigation();

    // Find the current route based on the location
    const currentRoute = findCurrentRoute(location.pathname, routes);
    const brandText = currentRoute ? currentRoute.name : 'Dashboard';

    // Update breadcrumb whenever location changes
    useEffect(() => {
        if (currentRoute && (breadcrumb.length === 0 || breadcrumb[breadcrumb.length - 1].path !== location.pathname)) {
            updateBreadcrumb({
                name: brandText, path: location.pathname,
            });
        }
    }, [location.pathname, brandText, currentRoute, breadcrumb, updateBreadcrumb]);

    useEffect(() => {
        window.addEventListener('scroll', changeNavbar);

        return () => {
            window.removeEventListener('scroll', changeNavbar);
        };
    }, []);

    const changeNavbar = () => {
        if (window.scrollY > 1) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    // Props destructuring
    const {
        secondary, mini, setMini, theme, setTheme,
    } = props;

    // Styling variables
    let mainText = useColorModeValue('navy.700', 'white');
    let secondaryText = useColorModeValue('gray.700', 'white');
    let navbarPosition = 'fixed';
    let navbarFilter = 'none';
    let navbarBackdrop = 'blur(20px)';
    let navbarShadow = 'none';
    let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
    let navbarBorder = 'transparent';
    let secondaryMargin = '0px';
    let paddingX = '15px';
    let gap = '0px';

    return (<Box
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        backgroundPosition="center"
        backgroundSize="cover"
        borderRadius="16px"
        borderWidth="1.5px"
        borderStyle="solid"
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration="0.25s, 0.25s, 0.25s, 0s"
        transitionProperty="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{xl: 'center'}}
        display={secondary ? 'block' : 'flex'}
        minH="75px"
        justifyContent={{xl: 'center'}}
        lineHeight="25.6px"
        mx="auto"
        mt={secondaryMargin}
        pb="8px"
        right={{base: '12px', md: '30px', lg: '30px', xl: '30px'}}
        px={{
            sm: paddingX, md: '10px',
        }}
        ps={{
            xl: '12px',
        }}
        pt="8px"
        top={{base: '12px', md: '16px', xl: '18px'}}
        w={mini === false ? {
            base: 'calc(100vw - 6%)',
            md: 'calc(100vw - 8%)',
            lg: 'calc(100vw - 6%)',
            xl: 'calc(100vw - 350px)',
            '2xl': 'calc(100vw - 365px)',
        } : mini === true && props.hovered === true ? {
            base: 'calc(100vw - 6%)',
            md: 'calc(100vw - 8%)',
            lg: 'calc(100vw - 6%)',
            xl: 'calc(100vw - 350px)',
            '2xl': 'calc(100vw - 365px)',
        } : {
            base: 'calc(100vw - 6%)',
            md: 'calc(100vw - 8%)',
            lg: 'calc(100vw - 6%)',
            xl: 'calc(100vw - 185px)',
            '2xl': 'calc(100vw - 200px)',
        }}
    >
        <Flex
            w="100%"
            flexDirection={{
                sm: 'column', md: 'row',
            }}
            alignItems={{xl: 'center'}}
            mb={gap}
        >
            <Box mb={{sm: '8px', md: '0px'}}>
                <Breadcrumb separator="/">
                    {breadcrumb.slice(-2).map((crumb, index) => (
                        <BreadcrumbItem key={index} color={secondaryText} fontSize="sm" mb="0px">
                            {index !== breadcrumb.length - 1 ? (<BreadcrumbLink
                                href="#"
                                color={secondaryText}
                                onClick={() => handleNavigate(crumb.path)}
                            >
                                {crumb.name}
                            </BreadcrumbLink>) : (<Text color={mainText}>{crumb.name}</Text>)}
                        </BreadcrumbItem>))}
                </Breadcrumb>

                {/* Here we create navbar brand, based on route name */}
                <Link
                    color={mainText}
                    href="#"
                    bg="inherit"
                    borderRadius="inherit"
                    fontWeight="bold"
                    fontSize="34px"
                    _hover={{color: mainText}}
                >
                    {brandText}
                </Link>
            </Box>
            <Box ms="auto" w={{sm: '100%', md: 'unset'}}>
                <AdminNavbarLinks
                    mini={mini}
                    setMini={setMini}
                    theme={theme}
                    setTheme={setTheme}
                    secondary={secondary}
                />
            </Box>
        </Flex>
    </Box>);
}