import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Text,
  Box,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import axios from 'axios';
import cookie from 'cookie';

// Import custom components
import Banner from 'components/users/Banner';
import General from 'components/users/General';
import Notifications from 'components/users/Notifications';
// Assets
import banner from 'assets/img/auth/banner.png';
import avatar from 'assets/img/avatars/avatar4.png';
import NftProfile from 'assets/img/nfts/NftProfile.png';

export default function UserDetail() {
  const { userUuid } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[name];
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const companyToken = getCookie('x-company');
        const userToken = getCookie('token');
        const response = await axios.get(
          `https://api.perxify.com/user/${userUuid}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'x-company': companyToken,
            },
          },
        );
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        throw ('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, [userUuid]);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (!userData) {
    return <Text>No user data found</Text>;
  }

  const loyaltyPrograms = userData.loyalty.filter(
    (purchase) => purchase.loyalty_program_uuid,
  );
  const regularPrograms = userData.purchases.filter(
    (purchase) => !purchase.loyalty_program_uuid,
  );

  const spent =
    userData.purchases.reduce(
      (total, purchase) => total + purchase.purchase_price,
      0,
    ) || 0;

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Grid
        templateColumns={{
          base: '1fr',
          lg: '1.34fr 1fr 1.62fr',
        }}
        templateRows={{
          base: 'repeat(3, 1fr)',
          lg: '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        <Banner
          gridArea="1 / 1 / 2 / 2"
          banner={banner}
          avatar={avatar}
          name={userData.full_name}
          lastlogin={userData.last_login}
          purchases={userData.purchases.length}
          moneySpent={Math.round((spent + Number.EPSILON) * 100) / 100}
        />
        <General
          gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
          minH="365px"
          pe="20px"
          userData={userData}
        />
        <Notifications
          gridArea={{
            base: '3 / 1 / 4 / 2',
            lg: '1 / 3 / 2 / 4',
          }}
          used={userData.notifications_used}
          total={userData.notifications_total}
        />
      </Grid>
      <Grid
        mb="20px"
        templateColumns={{
          base: '1fr',
          lg: 'repeat(2, 1fr)',
          '2xl': '1.34fr 1.62fr 1fr',
        }}
        templateRows={{
          base: '1fr',
          lg: 'repeat(2, 1fr)',
          '2xl': '1fr',
        }}
        gap={{ base: '20px', xl: '20px' }}
      >
        <Box
          gridArea="1 / 1 / 2 / 2"
          p="4"
          borderWidth="1px"
          borderRadius="md"
          mb="4"
        >
          <Text fontSize="xl" fontWeight="bold">
            Purchases
          </Text>
          <Tabs>
            <TabList>
              <Tab>Loyalty Products</Tab>
              <Tab>Other Products</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {loyaltyPrograms.map((purchase) => (
                  <Box
                    key={purchase.uuid}
                    p="4"
                    borderWidth="1px"
                    borderRadius="md"
                    mb="4"
                    display="flex"
                    alignItems="center"
                  >
                    <Image
                      boxSize="50px"
                      src={
                        purchase.images && purchase.images.length > 0
                          ? purchase.images[0].image_url
                          : NftProfile
                      }
                      alt={purchase.product_name}
                      mr="4"
                    />
                    <Box>
                      <Text>{purchase.product_name}</Text>
                      <Text>{purchase.product_description}</Text>
                    </Box>
                  </Box>
                ))}
              </TabPanel>
              <TabPanel>
                {regularPrograms.map((purchase) => (
                  <Box
                    key={purchase.uuid}
                    p="4"
                    borderWidth="1px"
                    borderRadius="md"
                    mb="4"
                    display="flex"
                    alignItems="center"
                  >
                    <Image
                      boxSize="50px"
                      src={
                        purchase.images && purchase.images.length > 0
                          ? purchase.images[0].image_url
                          : NftProfile
                      }
                      alt={purchase.product_name}
                      mr="4"
                    />
                    <Box>
                      <Text>{purchase.product_name}</Text>
                      <Text>{purchase.product_description}</Text>
                    </Box>
                  </Box>
                ))}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Grid>
    </Box>
  );
}
