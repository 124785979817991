// utils/routeUtils.js

// Function to strip dynamic segments from the route path
export const stripDynamicSegments = (routePath) => {
    return routePath.replace(/:\w+/g, '');
};

// Helper function to find the current route
export const findCurrentRoute = (path, routesArray) => {
    let matchedRoute = null;
    let maxMatchedLength = 0;

    for (let route of routesArray) {
        let fullPath = route.layout ? route.layout + route.path : route.path;
        let cleanedFullPath = stripDynamicSegments(fullPath);

        if (cleanedFullPath && path.startsWith(cleanedFullPath)) {
            let matchedLength = cleanedFullPath.length;
            if (matchedLength > maxMatchedLength) {
                matchedRoute = route;
                maxMatchedLength = matchedLength;
            }
        }

        if (route.items) {
            const nestedRoute = findCurrentRoute(path, route.items);
            if (nestedRoute) {
                let nestedFullPath = nestedRoute.layout ? nestedRoute.layout + nestedRoute.path : nestedRoute.path;
                let cleanedNestedFullPath = stripDynamicSegments(nestedFullPath);
                let nestedMatchedLength = cleanedNestedFullPath.length;

                if (nestedMatchedLength > maxMatchedLength) {
                    matchedRoute = nestedRoute;
                    maxMatchedLength = nestedMatchedLength;
                }
            }
        }
    }
    return matchedRoute;
};