import axios from 'axios';
import cookie from 'cookie';

const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[name];
};

const CompanyToken = getCookie('x-company');
const UserToken = getCookie('token');

export const getCompanies = async () => {
    const response = await axios.get('https://api.perxify.com/companies', {
        headers: {
            'x-company': CompanyToken, Authorization: `Bearer ${UserToken}`,
        },
    },);
    return response.data;
};

export const getCompanyDetails = async (companyUuid) => {
    const response = await axios.get(`https://api.perxify.com/company/${companyUuid}`, {
        headers: {
            'x-company': CompanyToken, Authorization: `Bearer ${UserToken}`,
        },
    },);
    return response.data;
};

export const createCompany = async (data) => {
    try {
        const response = await axios.post('https://api.perxify.com/companies', data, {
            headers: {
                'x-company': CompanyToken, Authorization: `Bearer ${UserToken}`,
            },
        },);
        return response.data;
    } catch (error) {
        console.error('Error creating company:', error);
        throw error;
    }
};

export const updateCompany = async (companyUuid, data) => {
    try {
        const response = await axios.put(`https://api.perxify.com/companies/${companyUuid}`, data, {
            headers: {
                'x-company': CompanyToken, Authorization: `Bearer ${UserToken}`,
            },
        },);
        return response.data;
    } catch (error) {
        console.error('Error updating company:', error);
        throw error;
    }
};

export const deleteCompany = async (companyUuid) => {
    try {
        await axios.delete(`https://api.perxify.com/companies/${companyUuid}`, {
            headers: {
                'x-company': CompanyToken, Authorization: `Bearer ${UserToken}`,
            },
        });
    } catch (error) {
        console.error('Error deleting company:', error);
        throw error;
    }
};