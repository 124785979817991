import { position } from "stylis";

// CookieConsentConfig.js
const getConfig = (domain, isFlagEnabled) => {
  const config = {
    cookie: {
      name: 'cookie_consent',
      domain: window.location.hostname, // Ensure this is set correctly, or use a specific domain
      path: '/', // Ensure it's accessible across all paths
      sameSite: "Lax", // Control cross-site behavior
      expiresAfterDays: 365, // Make sure it doesn't expire too soon
    },

    onFirstConsent: ({ cookie }) => {
      console.log('onFirstConsent fired', cookie);
    },

    onConsent: ({ cookie }) => {
      console.log('onConsent fired!', cookie);
    },

    onChange: ({ changedCategories, changedServices }) => {
      console.log('onChange fired!', changedCategories, changedServices);
    },

    onModalReady: ({ modalName }) => {
      console.log('Modal ready:', modalName);
    },

    onModalShow: ({ modalName }) => {
      console.log('Modal visible:', modalName);
    },

    onModalHide: ({ modalName }) => {
      console.log('Modal hidden:', modalName);
    },

    guiOptions: {
      consentModal: {
        layout: 'cloud inline', // Change to 'bar' for full-width
        position: 'bottom full', // Full width at the bottom
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: 'bar', // Default layout
        equalWeightButtons: true,
        flipButtons: false,
        position:'right',
      },
    },

    categories: {
      necessary: {
        enabled: true,
        readOnly: true,
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: match all cookies starting with '_ga'
            },
            {
              name: '_gid', // string: exact cookie name
            },
          ],
        },
        services: {
          googleAnalytics: {
            label: 'Google Analytics',
            onAccept: () => {
              console.log('Google Analytics accepted');
            },
            onReject: () => {
              console.log('Google Analytics rejected');
            },
          },
        },
      },
      ads: {
        services: {
          adService: {
            label: 'Advertising Service',
            onAccept: () => {
              console.log('Advertising accepted');
            },
            onReject: () => {
              console.log('Advertising rejected');
            },
          },
        },
      },
    },

    language: {
      default: 'en',
      translations: {
        en: {
          consentModal: {
            title: 'We use cookies',
            description:
              'We use cookies to improve your experience on our site. You can choose which categories to allow.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences',
          },
          preferencesModal: {
            title: 'Manage cookie preferences',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Save preferences',
            closeIconLabel: 'Close',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'Your Privacy Choices',
                description:
                  'In this panel, you can choose preferences regarding the processing of your personal data.',
              },
              {
                title: 'Strictly Necessary',
                description:
                  'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                linkedCategory: 'necessary',
              },
              {
                title: 'Performance and Analytics',
                description:
                  'These cookies help us understand how users interact with the website, enhancing performance.',
                linkedCategory: 'analytics',
              },
              {
                title: 'Targeting and Advertising',
                description:
                  'These cookies are used to deliver more relevant ads to you and measure ad performance.',
                linkedCategory: 'ads',
              },
            ],
          },
        },
      },
    },

    isFlagEnabled: isFlagEnabled,
  };

  return config;
};

export default getConfig;
