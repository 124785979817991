/* eslint-disable */
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  const linkColor = useColorModeValue(
    { base: "gray.400", lg: "white" },
    "white"
  );

  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        lg: "row",
      }}
      alignItems="center"
      justifyContent="space-between"
      px={{ base: "30px", md: "0px" }}
      pb="30px"
    >
      {/* Place the Text first */}
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        order={{ base: 2, lg: 1 }} // Adjust order for responsiveness
        mb={{ base: "20px", lg: "20px" }} // Add margin bottom on small screens
      >
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          Perxify. All Rights Reserved.
          <Link
            mx="3px"
            color={textColor}
            href="https://join.perxify.com"
            target="_blank"
            fontWeight="700"
          >
            https://join.perxify.com
          </Link>
        </Text>
      </Text>

      {/* Then the List */}
      <List
        display="flex"
        order={{ base: 1, lg: 2 }} // Adjust order for responsiveness
      >
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={linkColor}
            href="mailto:hello@simmmple.com"
          >
            Support
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={linkColor}
            href="https://www.simmmple.com/licenses"
          >
            License
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link
            fontWeight="500"
            color={linkColor}
            href="https://simmmple.com/terms-of-service"
          >
            Terms of Use
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight="500"
            color={linkColor}
            href="https://www.blog.simmmple.com/"
          >
            Blog
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
