import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAutomation } from '../../contexts/AutomationContext';
import axios from 'axios';
import cookie from 'cookie';
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
} from '@chakra-ui/react';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { createLoyaltyAutomation } from '../../api/automation';
import CreateAutomationModal from './CreateAutomationModal';

export default function AutomationsOverview() {
  const [automations, setAutomations] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const navigate = useNavigate();

  const { setFlowName, setFlowDescription, setAutomationUuid } =
    useAutomation();

  const handleViewDetails = (automationId) => {
    setAutomationUuid(automationId);
    navigate(`/admin/automations/builder/${automationId}`);
  };

  const handleCreateNewFlow = (name, description, loyaltyProgramId) => {
    setFlowName(name);
    setFlowDescription(description);
    const automationInfo = {
      name,
      description,
      loyalty_program_uuid: loyaltyProgramId,
    };
    createLoyaltyAutomation(automationInfo)
      .then((response) => {
        console.log('response', response);
        setAutomationUuid(response.uuid);
        navigate(`/admin/automations/builder/${response.uuid}`);
      })
      .catch((error) => {
        console.error('Error creating automation:', error);
      });
  };

  const getCookie = (name) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[name];
  };

  const CompanyToken = getCookie('x-company');
  const UserToken = getCookie('token');

  useEffect(() => {
    const fetchAutomations = async () => {
      try {
        const response = await axios.get(
          'https://api.perxify.com/loyalty-automations',
          {
            headers: {
              'x-company': CompanyToken,
              Authorization: `Bearer ${UserToken}`,
            },
          },
        );
        console.log('automations', response.data);
        setAutomations(response.data);
      } catch (error) {
        console.error('Error fetching automations:', error);
      }
    };

    fetchAutomations();
  }, [UserToken, CompanyToken]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '14px' }}
          color="gray.400"
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('description', {
      id: 'description',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          DESCRIPTION
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('activity_status', {
      id: 'activity_status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          ACTIVITY STATUS
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue() ? 'On' : 'Off'}
        </Text>
      ),
    }),
    columnHelper.accessor('start_date', {
      id: 'start_date',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          START DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('created_at', {
      id: 'created_at',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          CREATED AT
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          ACTIONS
        </Text>
      ),
      cell: (info) => (
        <Text
          cursor="pointer"
          color={brandColor}
          textDecoration="underline"
          fontSize="md"
          fontWeight="500"
          onClick={() => {
            handleViewDetails(info.row.original.uuid);
          }}
        >
          View Details
        </Text>
      ),
    }),
  ];

  const table = useReactTable({
    data: automations,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const createPages = (count) => {
    let arrPageCount = [];
    for (let i = 0; i < count; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      mt={{ sm: '150px', md: '125px', lg: '75px' }}
    >
      <Flex
        align={{ sm: 'flex-start', lg: 'flex-start' }}
        justify={{ sm: 'flex-start', lg: 'flex-start' }}
        w="100%"
        px="22px"
        mb="36px"
      >
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search..."
        />
        <Button
          ml="auto"
          colorScheme="blue"
          onClick={() => setIsModalOpen(true)}
        >
          New Flow
        </Button>
      </Flex>
      <CreateAutomationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        createFlow={handleCreateNewFlow}
      />
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  pe="10px"
                  borderColor={borderColor}
                  key={header.id}
                  colSpan={header.colSpan}
                >
                  {header.isPlaceholder ? null : (
                    <Flex
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted()] ?? null}
                    </Flex>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr px="20px" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  fontSize={{ sm: '14px' }}
                  minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                  borderColor={borderColor}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        <Text
          fontSize="sm"
          color="gray.500"
          fontWeight="normal"
          mb={{ sm: '24px', md: '0px' }}
        >
          Showing {pagination.pageSize * pagination.pageIndex + 1} to{' '}
          {pagination.pageSize * (pagination.pageIndex + 1) <=
          automations.length
            ? pagination.pageSize * (pagination.pageIndex + 1)
            : automations.length}{' '}
          of {automations.length} entries
        </Text>
        <div className="flex items-center gap-2">
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-effects"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pagination.pageSize === 5
                  ? 'none'
                  : table.getCanPreviousPage()
                    ? 'flex'
                    : 'none'
              }
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronLeft} w="16px" h="16px" color={textColor} />
            </Button>
            {createPages(table.getPageCount()).map((pageNumber, index) => (
              <Button
                variant="no-effects"
                transition="all .5s ease"
                onClick={() => table.setPageIndex(pageNumber - 1)}
                w="40px"
                h="40px"
                borderRadius="50%"
                bg={
                  pageNumber === pagination.pageIndex + 1
                    ? brandColor
                    : 'transparent'
                }
                border={
                  pageNumber === pagination.pageIndex + 1
                    ? 'none'
                    : '1px solid lightgray'
                }
                _hover={
                  pageNumber === pagination.pageIndex + 1
                    ? {
                        opacity: '0.7',
                      }
                    : {
                        bg: 'whiteAlpha.100',
                      }
                }
                key={index}
              >
                <Text
                  fontSize="sm"
                  color={
                    pageNumber === pagination.pageIndex + 1 ? '#fff' : textColor
                  }
                >
                  {pageNumber}
                </Text>
              </Button>
            ))}
            <Button
              variant="no-effects"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="transparent"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'white')}
              display={
                pagination.pageSize === 5
                  ? 'none'
                  : table.getCanNextPage()
                    ? 'flex'
                    : 'none'
              }
              _hover={{
                bg: 'whiteAlpha.100',
                opacity: '0.7',
              }}
            >
              <Icon as={MdChevronRight} w="16px" h="16px" color={textColor} />
            </Button>
          </Stack>
        </div>
      </Flex>
    </Flex>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      h="44px"
      w={{ lg: '390px' }}
      borderRadius="16px"
    />
  );
}
