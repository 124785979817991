import React from 'react';

import {Icon} from '@chakra-ui/react';
import {
    MdDashboard, MdHome, MdLock, MdOutlineShoppingCart,
} from 'react-icons/md';
import {FaGear, FaUser, FaIndustry} from 'react-icons/fa6';
import {BsBookmarkHeartFill} from 'react-icons/bs';
import {RiShieldUserFill} from 'react-icons/ri';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountApplications from 'views/admin/main/account/application';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';

import UsersOverview from 'pages/users/UsersOverview';
import UserDetail from 'pages/users/UserDetail';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault.jsx';
import LockCentered from 'views/auth/lock/LockCentered.jsx';
import LockDefault from 'views/auth/lock/LockDefault.jsx';
import SignIn from 'views/auth/signIn/SignIn.jsx';
import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
import SignUpDefault from 'views/auth/signUp/SignUpDefault.jsx';
import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';
import VerificationDefault from 'views/auth/verification/VerificationDefault.jsx';
import Callback from './pages/auth/Callback';
import GoogleAuthCallback from 'views/auth/signIn/GoogleAuthCallback';

// Perxify built pages
import AutomationBuilder from 'pages/automation/index';
import AutomationOverview from 'pages/automation/AutomationOverview';
import LoyaltyOverview from 'pages/loyalty/loyaltyOverview';
import LoyaltyDetail from 'pages/loyalty/loyaltyDetail';

// Companies Pages
import CompaniesOverview from 'pages/companies/CompaniesOverview';
import CompaniesDetail from 'pages/companies/CompaniesDetail';
import CompanyAdd from 'pages/companies/CompanyAdd';

const routes = [// --- Dashboard ---
    {
        name: 'Dashboard',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit"/>,
        path: '/dashboards/default',
        layout: '/admin',
        component: <DashboardsDefault/>,
    }, // --- Loyalty ---
    {
        name: 'Loyalty Programs', path: '/loyalty-programs', layout: '/admin', exact: false, icon: (<Icon
                as={BsBookmarkHeartFill}
                width="20px"
                height="20px"
                color="inherit"
            />), component: <LoyaltyOverview/>,
    }, // --- Automations ---
    {
        name: 'Automations',
        path: '/automations',
        layout: '/admin',
        exact: false,
        icon: <Icon as={FaGear} width="20px" height="20px" color="inherit"/>,
        component: <AutomationOverview/>,
    }, // --- Products ---
    {
        name: 'Products', path: '/products', icon: (<Icon
                as={MdOutlineShoppingCart}
                width="20px"
                height="20px"
                color="inherit"
            />), collapse: true, items: [{
            name: 'New Product',
            layout: '/admin',
            path: '/products/new-product',
            exact: false,
            component: <EcommerceNewProduct/>,
        }, {
            name: 'Product Overview',
            layout: '/admin',
            path: '/products/product-overview',
            exact: false,
            component: <EcommerceProductOverview/>,
        }, {
            name: 'Product Settings',
            layout: '/admin',
            path: '/products/settings',
            exact: false,
            component: <EcommerceProductSettings/>,
        }, {
            name: 'Product Page',
            layout: '/admin',
            path: '/products/page-example',
            exact: false,
            component: <EcommerceProductPage/>,
        }, {
            name: 'Order List',
            layout: '/admin',
            path: '/products/order-list',
            exact: false,
            component: <EcommerceOrderList/>,
        }, {
            name: 'Order Details',
            layout: '/admin',
            path: '/products/order-details',
            exact: false,
            component: <EcommerceOrderDetails/>,
        },],
    },

    // // --- Main pages ---
    {
        name: 'Main Pages',
        path: '/main',
        icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit"/>,
        collapse: true,
        hidden: true,
        items: [{
            name: 'Account', path: '/main/account', collapse: true, items: [{
                name: 'Billing',
                layout: '/admin',
                path: '/main/account/billing',
                exact: false,
                component: <AccountBilling/>,
            }, {
                name: 'Application',
                layout: '/admin',
                path: '/main/account/application',
                exact: false,
                component: <AccountApplications/>,
            }, {
                name: 'Invoice',
                layout: '/admin',
                path: '/main/account/invoice',
                exact: false,
                component: <AccountInvoice/>,
            }, {
                name: 'Settings',
                layout: '/admin',
                path: '/main/account/settings',
                exact: false,
                component: <AccountSettings/>,
            } /*          {
                      name: 'All Courses',
                      layout: '/admin',
                      path: '/main/account/all-courses',
                      exact: false,
                      component: <AccountAllCourses />,
                    },
                    {
                      name: 'Course Page',
                      layout: '/admin',
                      path: '/main/account/course-page',
                      exact: false,
                      component: <AccountCoursePage />,
                    },*/,],
        }, // --- Companies ---

            {
                name: 'Profile', path: '/main/profile', collapse: true, items: [{
                    name: 'Profile Overview',
                    layout: '/admin',
                    path: '/main/profile/overview',
                    exact: false,
                    component: <ProfileOverview/>,
                }, {
                    name: 'Profile Settings',
                    layout: '/admin',
                    path: '/main/profile/settings',
                    exact: false,
                    component: <ProfileSettings/>,
                }, {
                    name: 'News Feed',
                    layout: '/admin',
                    path: '/main/profile/newsfeed',
                    exact: false,
                    component: <ProfileNewsfeed/>,
                },],
            }, {
                name: 'Others', path: '/main/others', collapse: true, items: [{
                    name: 'Notifications',
                    layout: '/admin',
                    path: '/main/others/notifications',
                    exact: false,
                    component: <OthersNotifications/>,
                }, {
                    name: 'Pricing',
                    layout: '/auth',
                    path: '/main/others/pricing',
                    exact: false,
                    component: <OthersPricing/>,
                }, {
                    name: '404', layout: '/admin', path: '/main/others/404', exact: false, component: <OthersError/>,
                }, {
                    name: 'Messages',
                    layout: '/admin',
                    path: '/main/others/messages',
                    exact: false,
                    component: <Messages/>,
                },],
            },],
    },

    // --- Authentication ---
    {
        name: 'Authentication',
        path: '/auth',
        hidden: true,
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit"/>,
        collapse: true,
        items: [{
            name: 'Log In', layout: '/auth', path: '/login', component: <SignIn/>,
        },

            {
                name: 'Callback', layout: '/auth', path: '/callback', component: <Callback/>,
            }, {
                name: 'Google Auth Callback',
                layout: '/auth',
                path: '/google-auth-callback',
                component: <GoogleAuthCallback/>,
            },

            // --- Sign In ---

            // --- Sign Up ---
            {
                name: 'Sign Up', path: '/sign-up', collapse: true, items: [{
                    name: 'Default', layout: '/auth', path: '/sign-up/default', component: <SignUpDefault/>,
                }, {
                    name: 'Centered', layout: '/auth', path: '/sign-up/centered', component: <SignUpCentered/>,
                },],
            }, // --- Verification ---
            {
                name: 'Verification', path: '/verification', collapse: true, items: [{
                    name: 'Default', layout: '/auth', path: '/verification/default', component: <VerificationDefault/>,
                }, {
                    name: 'Centered',
                    layout: '/auth',
                    path: '/verification/centered',
                    component: <VerificationCentered/>,
                },],
            }, // --- Lock ---
            {
                name: 'Lock', path: '/lock', collapse: true, items: [{
                    name: 'Default', layout: '/auth', path: '/lock/default', component: <LockDefault/>,
                }, {
                    name: 'Centered', layout: '/auth', path: '/lock/centered', component: <LockCentered/>,
                },],
            }, // --- Forgot Password ---
            {
                name: 'Forgot Password', path: '/forgot-password', collapse: true, items: [{
                    name: 'Default',
                    layout: '/auth',
                    path: '/forgot-password/default',
                    component: <ForgotPasswordDefault/>,
                }, {
                    name: 'Centered',
                    layout: '/auth',
                    path: '/forgot-password/centered',
                    component: <ForgotPasswordCentered/>,
                },],
            },],
    }, {
        name: 'Users',
        layout: '/admin',
        path: '/users/users-overview',
        exact: false,
        icon: <Icon as={FaUser} width="20px" height="20px" color="inherit"/>,
        component: <UsersOverview/>,
    }, {
        name: 'Super Admin',
        path: '/superadmin',
        collapse: true,
        icon: (<Icon as={RiShieldUserFill} width="20px" height="20px" color="inherit"/>),
        items: [{
            name: 'Companies', path: '/superadmin/companies', layout: '/admin', exact: true, // Set exact to true
            component: <CompaniesOverview/>, icon: (<Icon as={FaIndustry} width="20px" height="20px" color="inherit"/>),
        },],
    },

    // --- Hidden Routes ---
    {
        name: 'Company Detail', layout: '/admin', path: '/superadmin/companies/:companyUuid', // Matches the dynamic route
        exact: true, component: <CompaniesDetail/>, hidden: true,
    }, {
        name: 'Add Company',
        layout: '/admin',
        path: '/superadmin/companies/add',
        component: <CompanyAdd/>,
        hidden: true,
    },


    // --- Hidden Routes ---


    {
        name: 'Automation Builder',
        path: '/automations/builder/:uuid?',
        layout: '/admin',
        exact: false,
        hidden: true,
        component: <AutomationBuilder/>,
    }, {
        name: 'User Detail',
        layout: '/admin',
        path: '/users/user/:userUuid',
        exact: false,
        hidden: true,
        component: <UserDetail/>,
    }, {
        name: 'Loyalty Detail',
        layout: '/admin',
        path: '/loyalty-programs/:uuid',
        exact: false,
        hidden: true,
        component: <LoyaltyDetail/>,
    },];

export default routes;
